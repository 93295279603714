import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    redirect: "/portal/menubookEditor/content",
  },
  {
    path: "/portal",
    name: "Portal",
    component: () => import("../layouts/PortalLayout.vue"),
    children: [
      {
        path: "menubookeditor",
        name: "MenuboekEditor",
        component: () => import("../components/PassThrough.vue"),
        children: [
          {
            path: "content",
            name: "MenubookEditorContent",
            component: () => import("../views/menubookEditor/Content.vue"),
          },
          {
            path: "personalisation",
            name: "MenubookEditorPersonalisation",
            component: () =>
              import("../views/menubookEditor/Personalisation.vue"),
          },
        ],
      },
      {
        path: "websiteeditor",
        name: "WebsiteEditor",
        component: () => import("../components/PassThrough.vue"),
        children: [
          {
            path: "main",
            name: "WebsiteEditorMain",
            component: () => import("../views/websiteEditor/Main.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../layouts/AuthLayout.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/public/Login.vue"),
      },
      {
        path: "forgotpassword",
        name: "ForgotPassword",
        component: () => import("../views/public/ForgotPassword.vue"),
      },
      {
        path: "resetpassword/:email",
        name: "ForgotPasswordReset",
        component: () => import("../views/public/ForgotPasswordReset.vue"),
      },
    ],
  },
  {
    path: "/menuboek/:horecaId",
    name: "MenuboekViewer",
    component: () => import("../views/public/MenuboekViewer.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "PageNotFound",
    component: () => import("../views/public/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
