const state = () => ({
  notifications: [],
});

const getters = {
  notifications: (state) => state.notifications,
};

const mutations = {
  setNotification: (state, notification) => {
    notification.id = Date.now() + notification.message;
    notification.duration = 4;
    state.notifications.push(notification);
  },
  removeNotification: (state, index) => state.notifications.splice(index, 1),
};

const actions = {
  async findAndRemoveNotification({ getters, commit }, id) {
    let notificationIndex = null;
    getters.notifications.forEach((notification, index) => {
      if (notification.id === id) {
        notificationIndex = index;
      }
    });
    commit("removeNotification", notificationIndex);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
