import { createStore } from "vuex";

// import modules
import moduleAuth from "./modules/moduleAuth";
import moduleMenuboek from "./modules/moduleMenuboek";
import moduleMenuboekAdmin from "./modules/moduleMenuboekAdmin";
import moduleNotification from "./modules/moduleNotification";
import moduleUser from "./modules/moduleUser";
import moduleBusiness from "./modules/moduleBusiness";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: moduleAuth,
    menuboek: moduleMenuboek,
    menuboekAdmin: moduleMenuboekAdmin,
    notification: moduleNotification,
    user: moduleUser,
    business: moduleBusiness,
  },
});
