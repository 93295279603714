import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronRight,
  faChevronLeft,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faPlus,
  faInfoCircle,
  faTimes,
  faChevronDown,
  faChevronUp,
  faCog,
  faBell,
  faBookOpen,
  faFileImage,
  faGlobe,
  faHotel
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faPlus,
  faInfoCircle,
  faTimes,
  faCog,
  faBell,
  faBookOpen,
  faFileImage,
  faGlobe,
  faHotel
);

createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
