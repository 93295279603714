const { api_url } = require("../../config/url");

const state = () => ({
  menuboek: {},
  config: {},
  menubookName: "",
});

const getters = {
  menuboek: (state) => state.menuboek,
  config: (state) => state.config,
  menubookName: (state) => state.menubookName,
};

const mutations = {
  setMenuboek: (state, menuboek) => (state.menuboek = menuboek),
  setConfig: (state, config) => (state.config = config),
  setMenubookName: (state, name) => (state.menubookName = name),
  clearMenuboek: (state) => (state.menuboek = []),
  toggleMenuboekChapter: (state, startIndex) => {
    startIndex++;
    while (
      state.menuboek[startIndex].category === "subtype" ||
      state.menuboek[startIndex].category === "item"
    ) {
      state.menuboek[startIndex].show = !state.menuboek[startIndex].show;
      startIndex++;
    }
  },
};

const actions = {
  async fetchMenuboekItemsByHorecaId({ commit }, horecaId) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(api_url + "menuboek/" + horecaId, options);
    if (response.status === 200) {
      const menuboek = await response.json();
      menuboek.menuboek.forEach((item) => {
        item.show = item.category === "hoofdtype" ? true : false;
      });
      commit("setMenuboek", menuboek.menuboek);
      commit("setConfig", menuboek.config);
      commit("setMenubookName", menuboek.menuboekNaam);
    } else {
      const errorMessage = await response.json();
      console.log(errorMessage);
      alert("geen menuboek gevonden!");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
