import router from "../../router";
const { api_url } = require("../../config/url");

const state = () => ({
  tokens: {
    accessToken: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
  },
});

const getters = {
  accessToken: (state) => state.tokens.accessToken,
  refreshToken: (state) => state.tokens.refreshToken,
};

const mutations = {
  setTokens: function(state, tokens) {
    state.tokens.accessToken = tokens.access_token;
    state.tokens.refreshToken = tokens.refresh_token;
    localStorage.setItem("accessToken", tokens.access_token);
    localStorage.setItem("refreshToken", tokens.refresh_token);
  },
  clearTokens: function(state) {
    state.tokens = {};
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
  },
};

const actions = {
  async login({ commit }, credentials) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: credentials.email,
        paswoord: credentials.password,
      }),
    };
    const response = await fetch(api_url + "auth/login", options);
    if (response.status === 200) {
      const tokens = await response.json();
      commit("setTokens", tokens);
      router.push("/");
    } else {
      commit(
        "notification/setNotification",
        {
          message: "e-mail of wachtwoord is foutief",
          type: "warning",
        },
        { root: true }
      );
    }
  },

  // TODO: check response and act on response
  async logout({ commit, getters }) {
    console.log("logout")
    const options = {
      mehod: "GET",
      headers: {
        "Content-Type": "application/json",
        'access-token': getters.accessToken,
        'refresh-token': getters.refreshToken,
      },
    };
    fetch(api_url + "auth/logout", options);
    commit("clearTokens");
    sessionStorage.clear();
    localStorage.clear();
    router.push("/auth/login");
  },

  // return true if tokens are present
  // return false if no tokens are present
  async checkLoggedIn({ getters }) {
    if (getters.refreshToken.lenght > 0 && getters.accessToken.lenght > 0) {
      return true;
    } else {
      return false;
    }
  },

  async requestForgotPasswordMail({ commit }, email) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      api_url + "auth/newpasswordcode/" + email,
      options
    );
    if (response.status === 200) {
      commit(
        "notification/setNotification",
        {
          message: "De e-mail om je wachtwoord te resetten is verzonden",
          type: "succes",
        },
        { root: true }
      );
      return true;
    } else {
      commit(
        "notification/setNotification",
        {
          message:
            "Dit e-meiladres is niet gevonden als een bestaande gebruiker",
          type: "error",
        },
        { root: true }
      );
      return false;
    }
  },

  async resetPasswordByPin({ commit }, payload) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(api_url + "auth/setnewpasswordbypin", options);
    if (response.status === 200) {
      router.push("/auth/login");
      commit(
        "notification/setNotification",
        { message: "Wachtwoord is aangepast", type: "succes" },
        { root: true }
      );
    } else if (response.status === 401) {
      const body = await response.json();
      let message = null;
      if (body.code === 4011) {
        message =
          "Email adress dat bij de link hoort is niet gevonden of bestaat niet";
      } else {
        message = "De bijgevoegde pin is foutief of ouder dan 30 minuten";
      }
      commit(
        "notification/setNotification",
        {
          message: message,
          type: "warning",
        },
        { root: true }
      );
    } else {
      commit(
        "notification/setNotification",
        {
          message: "Er is iets fout gegaan, probeer later opnieuw",
          type: "error",
        },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
