const { api_url } = require("../../config/url");
import router from '../../router';

const state = () => ({
  lastName: sessionStorage.getItem("userLastName") || "",
  firstName: sessionStorage.getItem("userFirstName") || "",
});

const getters = {
  user: (state) => {
    return {
      lastName: state.lastName,
      firstName: state.firstName,
    };
  },
  activeHorecaBusiness: (state) => {
    return state.activeHorecaBusiness;
  },
};

const mutations = {
  setUser: (state, { naam, voornaam }) => {
    state.lastName = naam;
    state.firstName = voornaam;
  },
};

const actions = {
  async fetchUser({ commit, rootGetters }) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-store, max-age=0",
        'access-token': rootGetters["auth/accessToken"],
        'refresh-token': rootGetters["auth/refreshToken"],
      },
    };
    const response = await fetch(api_url + "user/getUser", options);
    const body = await response.json();
    if (
      response.headers.get("new-access-token") !== null &&
      response.headers.get("new-refresh-token") !== null
    ) {
      commit(
        "auth/setTokens",
        {
          access_token: response.headers.get("new-access-token"),
          refresh_token: response.headers.get("new-refresh-token"),
        },
        { root: true }
      );
    }
    if (response.status === 200) {
      commit("setUser", body);
    } else if (response.status === 401) {
      console.log("not authenticeted, redirect to login page")
      router.push('/auth/login')
    } else {
      console.log(body);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
