import router from "../../router";
const { api_url } = require("../../config/url");

const state = () => ({
  businesses: JSON.parse(sessionStorage.getItem("businesses")) || [{id: null, name: '', portalProducts: {}, selected: true}],
});

const getters = {
  allBussinesses: (state) => state.businesses,
  selectedBusinessId: (state) =>
    state.businesses.find((business) => business.selected === true).id,
  selectedBusinessName: (state) =>
    state.businesses.find((business) => business.selected === true).name,
  selectedBusinessPortalProducts: (state) =>
    state.businesses.find((business) => business.selected === true)
      .portalProducts,
};

const mutations = {
  setBusinesses: (state, businessesArray) => {
    state.businesses = [];
    businessesArray.forEach((business) => {
      state.businesses.push({
        id: business.id,
        name: business.name,
        portalProducts: business.portal_products,
        selected: false,
      });
    });
    sessionStorage.setItem("businesses", JSON.stringify(state.businesses));
  },
  setBusinessSelected: (state, id) => {
    state.businesses.forEach((business) => {
      business.selected = business.id === id ? true : false;
    });
    sessionStorage.setItem("businesses", JSON.stringify(state.businesses));
  },
};

const actions = {
  async fetchBusinesses({ commit, rootGetters }) {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-store, max-age=0",
        'access-token': rootGetters["auth/accessToken"],
        'refresh-token': rootGetters["auth/refreshToken"],
      },
    };
    const response = await fetch(api_url + "business", options);
    const body = await response.json();
    if (
      response.headers.get("new-access-token") !== null &&
      response.headers.get("new-refresh-token") !== null
    ) {
      commit(
        "auth/setTokens",
        {
          access_token: response.headers.get("new-access-token"),
          refresh_token: response.headers.get("new-refresh-token"),
        },
        { root: true }
      );
    }
    if (response.status === 200) {
      commit("setBusinesses", body);
      commit("setBusinessSelected", body[0].id);
    } else if (response.status === 401) {
      console.log("not authenticeted, redirect to login page")
      router.push('/auth/login')
    } else {
      console.log(body);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
