<template>
  <router-view />
</template>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

* {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
}

body {
  background-color: $lm-background-color;
  color: $lm-text-color;
}
</style>
