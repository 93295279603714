import router from "../../router";
const { api_url } = require("../../config/url");
import { cloneDeep, isEqual } from "lodash";

const state = () => ({
  menuboekOriginal:
    JSON.parse(sessionStorage.getItem("menubookOriginal")) || [],
  menuboekEdited: JSON.parse(sessionStorage.getItem("menubookEdited")) || [],
  menuboekName: sessionStorage.getItem("menubookName") || "",
  configOriginal:
    JSON.parse(sessionStorage.getItem("menubookConfigOriginal")) || {},
  configEdited:
    JSON.parse(sessionStorage.getItem("menubookConfigEdited")) || {},
  isFetchingMenuboek: false,
});

const getters = {
  menuboekName: (state) => {
    return state.menuboekName;
  },
  editedEqualsOriginalMenubook: (state) => {
    return isEqual(state.menuboekEdited, state.menuboekOriginal);
  },
  editedEqualsOriginalConfig: (state) => {
    return isEqual(state.configEdited, state.configOriginal);
  },
  findRangeOfMenuboekItems: (state) => (id) => {
    let startIndex = 0;
    let range = 0;
    let inRange = false;
    let startIndexCategory = "";
    state.menuboekEdited.forEach((item, index) => {
      if (item._id === id) {
        //find  id in menuboek
        startIndex = index;
        inRange = true;
        startIndexCategory = state.menuboekEdited[index].category;
      } else if (inRange) {
        //when inRange check if out of range
        if (startIndexCategory === item.category) {
          inRange = false;
        }
        if (startIndexCategory === "item") {
          inRange = false;
        }
        if (startIndexCategory === "subtype" && item.category === "hoofdtype") {
          inRange = false;
        }
      }

      if (inRange) {
        range++;
      }
    });
    return { startIndex, range, parentType: startIndexCategory };
  },
};

const mutations = {
  setMenuboekOriginal: (state, menuboek) => {
    state.menuboekOriginal = menuboek;
    sessionStorage.setItem("menubookOriginal", JSON.stringify(menuboek));
  },
  setMenuboekEdited: (state, menuboek) => {
    state.menuboekEdited = menuboek;
    sessionStorage.setItem("menubookEdited", JSON.stringify(menuboek));
  },
  setMenuboekName: (state, name) => {
    state.menuboekName = name;
    sessionStorage.setItem("menubookName", name);
  },
  setConfigOriginal: (state, config) => {
    state.configOriginal = config;
    sessionStorage.setItem("menubookConfigOriginal", JSON.stringify(config));
  },
  setConfigEdited: (state, config) => {
    state.configEdited = config;
    sessionStorage.setItem("menubookConfigEdited", JSON.stringify(config));
  },
  setIsFetchingMenuboekToFalse: (state) => (state.isFetchingMenuboek = false),
  setIsFetchingMenuboekToTrue: (state) => (state.isFetchingMenuboek = true),
  deleteMenuboekItemsByRange: (state, itemRange) =>
    state.menuboekEdited.splice(itemRange.startIndex, itemRange.range),
  moveMenuboekItemBehindOtherMenuboekItem: (
    state,
    {
      movingItemId,
      movingItemRangeOfChildren,
      destinationItemId,
      destinationItemRangeOfChildren,
    }
  ) => {
    if (movingItemId !== destinationItemId) {
      const movingItemArray = state.menuboekEdited.slice(
        movingItemRangeOfChildren.startIndex,
        movingItemRangeOfChildren.startIndex + movingItemRangeOfChildren.range
      );
      // delete moving item and all it's children
      state.menuboekEdited.splice(
        state.menuboekEdited.findIndex((item) => item._id === movingItemId),
        movingItemRangeOfChildren.range
      );

      // add moving item with aal it's children at the right index
      movingItemArray.reverse().forEach((item) => {
        state.menuboekEdited.splice(
          state.menuboekEdited.findIndex(
            (item) => item._id === destinationItemId
          ) + destinationItemRangeOfChildren.range,
          0,
          item
        );
      });
    }
  },
  toggleMenuboekItemVisibility: (state, id) => {
    state.menuboekEdited[
      state.menuboekEdited.findIndex((item) => item._id === id)
    ].visible = !state.menuboekEdited[
      state.menuboekEdited.findIndex((item) => item._id === id)
    ].visible;
  },
  updateMenubookConfigItem: (state, payload) => {
    state.configEdited[payload.configName] = payload.configValue;
  },
};

const actions = {
  async changeMenuboekEditedItemValue({ commit, state }, payload) {
    let menuboek = state.menuboekEdited;
    const id = payload.id.split("-");
    await menuboek.forEach((item) => {
      if (id[0] === item._id) {
        if (id.length === 2) {
          if (id[1] === "price") {
            if (payload.value.replace(/,/g, ".").split(".").length === 0) {
              item.price = 0;
            } else if (
              payload.value.replace(/,/g, ".").split(".").length <= 2
            ) {
              item.price = Number(payload.value.replace(/,/g, "."));
            } else {
              item.price = 0;
            }
          } else if (id[1] === "description") {
            item.description = payload.value;
          }
        } else if (id.length === 1) {
          item.name = payload.value;
        }
      }
    });
    commit("setMenuboekEdited", menuboek);
  },

  async fetchMenuboek({ commit, rootGetters }) {
    commit("setIsFetchingMenuboekToTrue");
    setTimeout(async function () {
      const options = {
        mehod: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        api_url + "menuboek/" + rootGetters["business/selectedBusinessId"],
        options
      );
      const menuboek = await response.json();
      if (
        response.headers.get("new-access-token") !== null &&
        response.headers.get("new-refresh-token") !== null
      ) {
        commit(
          "auth/setTokens",
          {
            access_token: response.headers.get("new-access-token"),
            refresh_token: response.headers.get("new-refresh-token"),
          },
          { root: true }
        );
      }
      if (response.status === 200) {
        commit("setMenuboekEdited", cloneDeep(menuboek.menuboek));
        commit("setMenuboekOriginal", cloneDeep(menuboek.menuboek));
        commit("setMenuboekName", menuboek.menuboekNaam);
        commit("setConfigOriginal", cloneDeep(menuboek.config));
        commit("setConfigEdited", cloneDeep(menuboek.config));
      } else if (response.status === 401) {
        router.push("/auth/login");
      } else {
        const errorMessage = await response.json();
        console.error(errorMessage);
      }
      commit("setIsFetchingMenuboekToFalse");
    }, 1000);
  },

  async updateMenuboek({ state, commit, rootGetters }) {
    commit("setIsFetchingMenuboekToTrue");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-store, max-age=0",
        'access-token': rootGetters["auth/accessToken"],
        'refresh-token': rootGetters["auth/refreshToken"],
      },
      body: JSON.stringify({ menuboek: state.menuboekEdited }),
    };
    const response = await fetch(api_url + "menuboek", options);
    if (response.status === 200) {
      if (
        response.headers.get("new-access-token") !== null &&
        response.headers.get("new-refresh-token") !== null
      ) {
        commit(
          "auth/setTokens",
          {
            access_token: response.headers.get("new-access-token"),
            refresh_token: response.headers.get("new-refresh-token"),
          },
          { root: true }
        );
      }
      const menuboek = await response.json();
      commit("setMenuboekOriginal", cloneDeep(menuboek.menuboek));
      commit(
        "notification/setNotification",
        {
          message: "Aanpassingen opgeslagen",
          type: "succes",
        },
        { root: true }
      );
    } else if (response.status === 401) {
      router.push("/auth/login");
      commit(
        "notification/setNotification",
        {
          message: "Je bent niet ingelogd",
          type: "error",
        },
        { root: true }
      );
    } else {
      const errorMessage = await response.json();
      console.error(errorMessage);
      commit("notification/setNotification", {
        message:
          "Het lijkt erop dat er iets is fout gegaan. We hebben je menuboek niet gevonden",
        type: "error",
      });
    }
    commit("setIsFetchingMenuboekToFalse");
  },

  async insertEmptyMenuboekItem(
    { commit, rootGetters, getters, dispatch },
    parentId
  ) {
    const rangeOfParentItem = getters.findRangeOfMenuboekItems(parentId);
    await dispatch("updateMenuboek");
    commit("setIsFetchingMenuboekToTrue");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-store, max-age=0",
        'access-token': rootGetters["auth/accessToken"],
        'refresh-token': rootGetters["auth/refreshToken"],
      },
      body: JSON.stringify({
        insertIndex: rangeOfParentItem.startIndex + rangeOfParentItem.range,
        parentType: rangeOfParentItem.parentType,
      }),
    };
    const response = await fetch(
      api_url + "menuboek/insertEmptyMenuboekItem",
      options
    );
    if (response.status === 200) {
      if (
        response.headers.get("new-access-token") !== null &&
        response.headers.get("new-refresh-token") !== null
      ) {
        commit(
          "auth/setTokens",
          {
            access_token: response.headers.get("new-access-token"),
            refresh_token: response.headers.get("new-refresh-token"),
          },
          { root: true }
        );
      }
      const menuboek = await response.json();
      commit("setMenuboekEdited", cloneDeep(menuboek.menuboek));
      commit("setMenuboekOriginal", cloneDeep(menuboek.menuboek));
    } else if (response.status === 401) {
      router.push("/auth/login");
    } else {
      const errorMessage = await response.json();
      console.error(errorMessage);
    }
    commit("setIsFetchingMenuboekToFalse");
  },

  async updateConfig({ state, commit, rootGetters }, logoImage) {
    const formData = new FormData();
    formData.append('config', JSON.stringify(state.configEdited));
    formData.append('image', logoImage);
    formData.append('bId', rootGetters["business/selectedBusinessId"])

    const options = {
      method: "POST",
      headers: {
        "Cache-Control": "no-store, max-age=0",
        'access-token': rootGetters["auth/accessToken"],
        'refresh-token': rootGetters["auth/refreshToken"],
      },
      body: formData,
    };
    const response = await fetch(api_url + "menuboek/config", options);
    if (response.status === 200) {
      if (
        response.headers.get("new-access-token") !== null &&
        response.headers.get("new-refresh-token") !== null
      ) {
        commit(
          "auth/setTokens",
          {
            access_token: response.headers.get("new-access-token"),
            refresh_token: response.headers.get("new-refresh-token"),
          },
          { root: true }
        );
      }
      const menuboek = await response.json();
      commit("setConfigOriginal", cloneDeep(menuboek.config));
      commit("setConfigEdited", cloneDeep(menuboek.config));
      commit(
        "notification/setNotification",
        {
          message: "Aanpassingen opgeslagen",
          type: "succes",
        },
        { root: true }
      );
    } else if (response.status === 401) {
      router.push("/auth/login");
      commit(
        "notification/setNotification",
        {
          message: "Je bent niet ingelogd",
          type: "error",
        },
        { root: true }
      );
    } else {
      const errorMessage = await response.json();
      console.error(errorMessage);
      commit("notification/setNotification", {
        message:
          "Het lijkt erop dat er iets is fout gegaan. We hebben je menuboek niet gevonden",
        type: "error",
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
